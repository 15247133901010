<template>
  <v-footer
    class="px-4"
    align="center"
    color="#f0f0f0"
    style="background-color: #f0f0f0 !important;height:35px"
    :padless="true"
    dense
    absolute
  >
    <v-flex xs2 class="footer-column text-xs-left">
      <!-- <a href="https://www.systemx.net" target="_blank"><v-img
        :src="require('../../../assets/img/full-logo.svg')"
        max-height="60px"
        contain
      ></v-img></a> -->
    </v-flex>
    <v-flex class="footer-column text-xs-center"> </v-flex>
    <v-flex xs5 class="footer-column text-xs-right">
      <v-layout dense>
        <v-flex
          ><v-select
            v-model="language"
            :items="languages"
            item-text="name"
            item-value="id"
            dense
            hide-details
          ></v-select></v-flex
        ><v-divider vertical></v-divider>
        <v-flex class="text-xs-center "><v-layout row class=" fill-height align-center">
          <v-flex xs12>
            <router-link to="/termsconditions"> Terms and Conditions</router-link>
          </v-flex>
        </v-layout></v-flex
        ><v-divider vertical></v-divider>
        <v-flex class="text-xs-center align-content-center"><v-layout row class=" fill-height align-center">
          <v-flex xs12> Copyright {{ year }} &#169; {{company.name}} </v-flex>
        </v-layout> </v-flex>
      </v-layout>
    </v-flex>
  </v-footer>
</template>

<script>
import moment from 'moment';
export default {
  name: 'pdf-footer',
    props: {
    company: {
      type: Object,
      default: ()=>{return{name:"", email:""}}
    },
    },
  data() {
    return {
      language: 1,
      languages: [
        { id: 1, name: 'English' }
      ],
    };
  },
  computed: {
    year() {
      return moment().format('YYYY');
    },
  },
};
</script>
<style  scoped>
.footer-column {
  /* padding-top: 5px;
  padding-bottom: 2px; */
}
/* /deep/ .v-select {
  padding-top: 0px;
  margin-top: 0px;
} */
/deep/ .v-text-field {
  /* padding-top: 0px; */
  margin-top: 0px;
  padding-top: 0;
}
</style>
