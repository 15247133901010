<template>
  <v-layout dense class="header pl-4 pr-2" align="center">
    <v-flex xs1>
      <v-layout row class="fill-height align-center">
        <v-flex xs12>
          <v-img
            :src="require('@/assets/img/MircomLogo.jpg')"
            max-height="40px"
            contain
          ></v-img>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs2> </v-flex>
    <v-flex class="text-xs-center title"
      ><v-layout row class="fill-height align-center">
        <v-flex xs12 headline>
          {{ title }}
        </v-flex>
      </v-layout></v-flex
    >
    <v-flex xs3 class="text-xs-right">
      <v-layout row class="align-center fill-height text-xs-right">
        <v-flex>
          <v-btn
            color="success"
            :disabled="disabledFinish"
            @click="handleSave"
            >finish</v-btn
          >
        </v-flex>
        <v-flex shrink>
          <v-btn icon @click="cancel" class="pa-0 mr-0">
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "add-card-header",
  props: {
    title: {
      type: String,
      default: "",
    },
    boss40AppUrl: {
      type: String,
      default: "",
    },
    disabledFinish: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancel")
    },
    handleSave(){
      this.$emit("save")
    }
  },
};
</script>
<style lang="scss" scoped>
.header {
  background-color: white;
  overflow: hidden;
  max-height: 50px;
}
</style>
